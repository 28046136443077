export interface UrlParamsType {
  id?: string;
  zoneId?: string;
  cameraCount?: string;
  accessToken?: string;
  reportId?: string;
}

export interface PageInterface {
  siteName?: string;
  userFullName?: string;
  customerId?: number;
  dealerName?: string;
  customerName?: string;
  siteId?: number;
  operatorId?: number;
  operatorReference?: string;
  commercialPartnerId?: number;
}

export type UserTypes = "CustomerUser" | "OperatorUser" | "GuestUser";
export type User = {
  id: string;
  fullName?: string;
  primaryEmail?: string;
  avatarImg?: string;
  countryReference?: string;
  customerId?: number;
  customerContactId?: string[];
  userType: UserTypes;
  userRole: string;
  permissions: string[];
  restrictions: string[];
  operatorId?: number;
};

interface IDaysGeneral {
  [key: string]: boolean[];
}

export interface IDays extends IDaysGeneral {
  armed: boolean[];
}

export interface IArmingInformation {
  isArmed: boolean;
  isException: boolean;
  isManualArmDisarm: boolean;
}

export interface IHighlightArmingInformation {
  isArmed: boolean;
  isException: boolean;
  isManualArmDisarm: boolean;
  start: number;
  end?: number;
  displayMessage?: string;
}

export interface IZone {
  id: number;
  name: string;
  days?: IDays;
  armingInformation?: IArmingInformation[];
  highlightArmingInformation?: IHighlightArmingInformation[];
  isArmed?: boolean;
  isException?: boolean;
  nextChangeDateTime?: string;
}

export interface IException {
  id?: number;
  name?: string;
  isArmed?: boolean;
  isFullDay?: boolean;
  startDateTime?: Date;
  endDateTime?: Date;
  zones?: IZone[];
}

export interface ISchedule {
  days?: IDays;
  zones?: IZone[];
}

export interface SiteGroupDTO {
  id: number;
  name: string;
  displayName: string;
  siteGroupId: number;
  reference: string;
}

export enum Environment {
  EMEA,
  US,
}

export type SystemDecommissionRequest = {
  id: number;
  systemId: number;
  requestedBy: string;
  actionedBy?: string;
  uTCDateTimeApproved?: Date;
  uTCDateTimeDeclined?: Date;
  uTCDateTimeCreated: Date;
  uTCDateToDecommission: Date;
  system: any;
};

export enum VisitorPassTheme {
  LIGHT = "LIGHT",
  DARK = "DARK",
}
