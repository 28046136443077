import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MainLayout from "./MainLayout";
import { ROUTES } from "../constants";
import { AuthContext } from "./AuthProvider";
import { getTokens } from "../services/apolloclient/accessTokens";
import { LogDetail } from "./SiteLogs/LogDetail";

//Auth - Login/Remember pages
const Auth = React.lazy(() => import("./Auth"));
//Sites
const Sites = React.lazy(() => import("./Sites"));
const Site = React.lazy(() => import("./Site"));
const SiteCreate = React.lazy(() => import("./SiteCreate"));
//Dealers
const Dealer = React.lazy(() => import("./Dealer"));
const Dealers = React.lazy(() => import("./Dealers"));
const DealerCreate = React.lazy(() => import("./DealerCreate"));
const DealerInfo = React.lazy(() => import("./DealerInfo"));
const VisitorPassBranding = React.lazy(() => import("./VisitorPassBranding"));
const DealerMetrics = React.lazy(() => import("./DealerMetrics"));
//Customers
const Customer = React.lazy(() => import("./Customer"));
const CustomerInfo = React.lazy(() => import("./CustomerInfo"));
const CustomerMetrics = React.lazy(() => import("./CustomerMetrics"));
const Customers = React.lazy(() => import("./Customers"));
const CustomerCreate = React.lazy(() => import("./CustomerCreate"));
//Tasks
const Tasks = React.lazy(() => import("./Tasks"));
const Task = React.lazy(() => import("./Task"));
const TaskCreate = React.lazy(() => import("./TaskCreate"));
//Users
const Users = React.lazy(() => import("./Users"));
const User = React.lazy(() => import("./User"));
const SiteContact = React.lazy(() => import("./SiteContacts/Contact"));
const UserCreate = React.lazy(() => import("./UserCreate"));
//Profile
const UserProfile = React.lazy(() => import("./UserProfile"));
//IncidentReports
const IncidentReports = React.lazy(() => import("./IncidentReports"));
const InteractiveReports = React.lazy(() => import("./InteractiveReports"));
//Shared Contacts
const SharedContacts = React.lazy(() => import("./SharedContacts/Contacts"));
const SharedContact = React.lazy(() => import("./SharedContacts/Contact"));
//Commissioning
const CommissioningMenu = React.lazy(() => import("./CommissioningMenu"));
const Commissioning = React.lazy(() => import("./Commissioning"));
const CommissioningInfo = React.lazy(() => import("./CommissioningInfo"));
const DecommissioningInfo = React.lazy(() => import("./DecommissioningInfo"));
const CommissioningApproval = React.lazy(() => import("./CommissioningApproval"));
const CommissioningRequests = React.lazy(() => import("./DecommissioningRequests"));
const PolicyAdminPanel = React.lazy(() => import("./PolicyAdminPanel"));

//Commercial Partner
const CommercialPartners = React.lazy(() => import("./CommercialPartner/CommercialPartners"));
const CommercialPartnerMenu = React.lazy(() => import("./CommercialPartner/CommercialPartnerMenu"));
const CommercialPartnerInfo = React.lazy(() => import("./CommercialPartner/CommercialPartnerInfo"));
const CommercialPartnerCreate = React.lazy(() => import("./CommercialPartner/CommercialPartnerCreate"));
const CommercialPartnerUser = React.lazy(() => import("./CommercialPartner/CommercialPartnerUsers"));
const CommercialPartnerUserInfo = React.lazy(() => import("./CommercialPartner/CommercialPartnerUserInfo"));

//Change Password
const ChangePassword = React.lazy(() => import("./ChangePassword"));
//Dealer Settings
const Operator = React.lazy(() => import("./Operator"));

//Logs
const Logs = React.lazy(() => import("./SiteLogs"));

const Dashboard = React.lazy(() => import("./Dashboard"));

const SharedInteractiveReportLanding = React.lazy(
  () => import("./SharedLandingPages/SharedInteractiveReportLanding")
);

//SceneSpecificAudio
const SceneSpecificAudio = React.lazy(() => import("./SceneSpecificAudio"));

//Day and night images
const QualityAssurance = React.lazy(() => import("./QualityAssurance"))
const CameraQA = React.lazy(() => import("./QualityAssurance/CameraQA"))

//Announcements
const Announcements = React.lazy(() => import("./Announcements"));
const CreateAnnouncement = React.lazy(() => import("./Announcements/Components/CreateAnnouncement"));
const EditAnnouncement = React.lazy(() => import("./Announcements/Components/EditAnnouncement"));

function App() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const { setAuthTokens, user } = React.useContext(AuthContext);
  
  //Although none of this code is asynchronous,
  //Removing async here will cause an error when refreshing
  //the pages as it will always redirect to the Auth page first
  //Which causes your location to redirect to sites automatically.
  //Make it make sense
  React.useEffect(() => {
    getTokens()
      .then(async (tokens: any) => {
        if (tokens) {
          await setAuthTokens(tokens);
        }
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [setAuthTokens]);

  if (loading) {
    return null;
  }

  if(!user)
  {
    return ( 
    <Suspense fallback={<p>loading</p>}>
      <Auth />
    </Suspense>)
  }

  if(user?.userRole === "CommercialPartner")
  {
    return (
      <Suspense fallback={<p>loading</p>}>
        <MainLayout>
          <Switch>
          <Route exact path={ROUTES.dashboard}>
              <Dashboard />
              </Route>
              <Redirect from="*" to={ROUTES.dashboard} /> 
          </Switch>
        </MainLayout>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<p>loading</p>}>
        <MainLayout>
          <Switch>
            <Route exact path={ROUTES.sites}>
              <Sites />
            </Route>
            <Route path={ROUTES.site}>
              <Site />
            </Route>
            <Route path={ROUTES.commissioningMenu}>
              <CommissioningMenu />
            </Route>
            <Route path={ROUTES.commissioning}>
              <Commissioning />
            </Route>
            <Route exact path={ROUTES.commissioningInfo}>
              <CommissioningInfo />
            </Route>
            <Route exact path={ROUTES.decommissioningInfo}>
              <DecommissioningInfo />
            </Route>
            <Route exact path={ROUTES.commissioningApproval}>
              <CommissioningApproval />
            </Route>
            <Route exact path={ROUTES.commissioningRequests}>
              <CommissioningRequests />
            </Route>
            <Route path={ROUTES.commercialPartnerMenu}>
              <CommercialPartnerMenu />
            </Route>
            <Route path={ROUTES.commercialPartners}>
              <CommercialPartners />
            </Route>
            <Route path={ROUTES.commercialPartnerUserInfo}>
              <CommercialPartnerUserInfo />
            </Route>
            <Route path={ROUTES.siteCreate}>
              <SiteCreate />
            </Route>
            <Route path={ROUTES.customer}>
              <Customer />
            </Route>
            <Route exact path={ROUTES.customerInfo}>
              <CustomerInfo />
            </Route>
            <Route path={ROUTES.customerCreate}>
              <CustomerCreate />
            </Route>
            <Route path={ROUTES.customers}>
              <Customers />
            </Route>
            <Route path={ROUTES.customerMetrics}>
              <CustomerMetrics />
            </Route>
            <Route path={ROUTES.dealer}>
              <Dealer />
            </Route>
            <Route path={ROUTES.dealerInfo}>
              <DealerInfo />
            </Route>
            <Route path={ROUTES.dealerMetrics}>
              <DealerMetrics />
            </Route>
            <Route path={ROUTES.dealerCreate}>
              <DealerCreate />
            </Route>
            <Route path={ROUTES.dealers}>
              <Dealers />
            </Route>
            <Route exact path={ROUTES.users}>
              <Users />
            </Route>
            <Route path={ROUTES.contact}>
              <SiteContact />
            </Route>
            <Route path={ROUTES.user}>
              <User />
            </Route>
            <Route path={ROUTES.userCreate}>
              <UserCreate />
            </Route>
            <Route exact path={ROUTES.interactiveReports}>
              <InteractiveReports />
            </Route>
            <Route exact path={ROUTES.dashboard}>
              <Dashboard />
            </Route>
            <Route exact path={ROUTES.sharedContacts}>
              <SharedContacts />
            </Route>
            <Route path={ROUTES.sharedContact}>
              <SharedContact />
            </Route>
            <Route exact path={ROUTES.profile}>
              <UserProfile />
            </Route>
            <Route exact path={ROUTES.sceneSpecificAudio}>
              <SceneSpecificAudio />
            </Route>
            <Route exact path={ROUTES.logs}>
              <Logs />
            </Route>
            <Route exact path={ROUTES.log}>
              <LogDetail />
            </Route>
            <Route exact path={ROUTES.reports}>
              <IncidentReports />
            </Route>
            <Route exact path={ROUTES.tasks}>
              <Tasks />
            </Route>
            <Route path={ROUTES.taskCreate}>
              <TaskCreate />
            </Route>
            <Route path={ROUTES.changePassword}>
              <ChangePassword />
            </Route>
            <Route path={ROUTES.operator}>
              <Operator />
            </Route>
            <Route path={ROUTES.operatorSettings}>
              <Operator />
            </Route>
            <Route path={ROUTES.policyAdminPanel}>
              <PolicyAdminPanel />
            </Route>
            <Route path={ROUTES.eagleEyeCredentials}>
              <Operator />
            </Route>
            <Route path={ROUTES.task}>
              <Task />
            </Route>
            <Route path={ROUTES.visitorPassBranding}>
              <VisitorPassBranding />
            </Route>
            <Route path={ROUTES.sharedInteractiveReport}>
              <SharedInteractiveReportLanding />
            </Route>
            <Route path={ROUTES.qualityAssurance}>
              <QualityAssurance />
            </Route>
            <Route path={ROUTES.cameraQA}>
              <CameraQA />
            </Route>
            <Route exact path={ROUTES.announcements}>
                <Announcements />
              </Route>
              <Route exact path={ROUTES.createAnnouncement}>
                <CreateAnnouncement />
              </Route>
              <Route exact path={ROUTES.editAnnouncement}>
                <EditAnnouncement />
              </Route>
            <Redirect from="*" to={ROUTES.sites} />      
          </Switch>
        </MainLayout>
    </Suspense>
  );
}
export default App;
